import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
	<Layout>
		<SEO title="Ryan Teuscher" />
		<div className="home-block">
			<p>
				<span role="img" aria-label="Hello. ">
					👋
				</span>{" "}
				I’m{" "}
				<a
					href="https://www.linkedin.com/in/ryanteuscher/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Ryan Teuscher
				</a>
				, a designer and product leader fortunate enough to work with the
				talented people at companies like the BBC, Notion, Patrón Tequila and
				the U.S. Geological Survey as well as&hellip;
			</p>
		</div>
		<div className="project-list">
			<div className="project-list-item">
				<Link to="/invision/">InVision</Link>
			</div>
			<div className="project-list-item">
				<Link to="/relay/">Relay</Link>
			</div>
			<div className="project-list-item">
				<Link to="/opentable/">OpenTable</Link>
			</div>
			<div className="project-list-item">
				<Link to="/iconic/">Iconic</Link>
			</div>
			<div className="project-list-item">
				<Link to="/general-electric/">General Electric</Link>
			</div>
			<div className="project-list-item">
				<Link to="/symantec/">Symantec</Link>
			</div>
			<div className="project-list-item">
				<Link to="/inkling/">Inkling</Link>
			</div>
			<div className="project-list-item">
				<Link to="/compfight/">Compfight</Link>
			</div>
		</div>
	</Layout>
)

export default IndexPage
